<template>
  <div class="page page-About">
    <template v-for="(part, index) in $t('lang.about')">
      <transition
        :enter-active-class="$store.state.ui.enterClassName"
        :leave-active-class="$store.state.ui.leaveClassName"
        :key="part.id"
      >
        <div
          class="page"
          v-if="part.id === showPart"
          key="part.id"
          :class="{ loaded: isSubPageLoaded }"
        >
          <div class="half half-about content">
            <div class="half__pic m50" :style="imgs[index].background"></div>
            <div class="half__text m25">
              <div
                class="magic-area"
                ref="magicOuter"
                :style="magicTextBlocks[part.id]"
              >
                <!--              <VuePerfectScrollbar class="scroll-area" :settings="psSettings">-->
                <div v-html="part.text" ref="magicInner"></div>
                <!--              </VuePerfectScrollbar>-->
              </div>
            </div>
          </div>
        </div>
      </transition>
    </template>

    <div :class="{ loaded: isLoaded }">
      <div class="navigation m100">
        <div class="pages" v-if="isMobile">
          {{ subPageIndex }}/{{ subPagesCount }}
        </div>
        <div
          class="prev link"
          :class="{ disable: showPart === firstPage }"
          @click="changePage('prev')"
        ></div>
        <div
          class="next link"
          :class="{ disable: showPart === lastPage }"
          @click="changePage('next')"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { loader } from '../mixins/loader';
import { isMobile } from '../mixins/isMobile';
import { functions } from '../mixins/functions';
import ENV from '@/environment';

export default {
  name: 'About',
  mixins: [loader, isMobile, functions],

  data() {
    return {
      pageData: { page: 'About', isHeaderHidden: true },
      showPart: 1,
      subPageIndex: 1,
      psSettings: {
        suppressScrollX: true,
      },
      parts: this.$t('lang.about'),
      imgs: [
        {
          backgroundImages: {
            '2x': ENV.REGION === 'by' ? '/img/by/8.png' : '/img/about1@2x.jpg',
          },
        },
        {
          backgroundImages: {
            '2x': ENV.REGION === 'by' ? '/img/by/6.png' : '/img/about2@2x.jpg',
          },
        },
      ],
    };
  },

  deactivated() {
    this.isLoaded = false;
  },

  activated() {
    this.$store.commit('ui/loaderON');
    this.onActivated();
  },

  computed: {
    lastPage() {
      return (
        this.parts[this.parts.length - 1] &&
        this.parts[this.parts.length - 1].id
      );
    },
    firstPage() {
      return this.parts[0] && this.parts[0].id;
    },
    subPagesCount() {
      return this.parts.length;
    },
  },

  watch: {
    showPart(val) {
      this.watchPart(val);
    },
  },

  methods: {
    onActivated() {
      if (this.imagesLoaded) {
        this.pageLoaded();
      } else {
        const images = [];

        for (const i in this.parts) {
          this.imgs[i].background = this.bgImgStyle(
            this.imgs[i].backgroundImages,
          );

          for (const j in this.imgs[i].backgroundImages) {
            images.push(this.imgs[i].backgroundImages[j]);
          }
        }

        this.loadImages(images);
      }

      this.watchPart();
    },

    watchPart(val) {
      // this.isSubPageLoaded = false;

      // turn off if mobile
      if (!this.isMobile) {
        this.magicText(0, true, this.showPart, true);
      }

      /*
      setTimeout(function () {
        this.isSubPageLoaded = true;
      }, 100);
      */
    },

    changePage(delta) {
      for (const i in this.parts) {
        if (delta === 'prev') {
          if (
            this.parts[i * 1 + 1] &&
            this.parts[i * 1 + 1].id === this.showPart
          ) {
            this.showPart = this.parts[i].id;
            this.subPageIndex--;
            break;
          }
        } else {
          if (
            this.parts[i * 1 - 1] &&
            this.parts[i * 1 - 1].id === this.showPart
          ) {
            this.showPart = this.parts[i].id;
            this.subPageIndex++;
            break;
          }
        }
      }
    },
  },
};
</script>

<style>
@media screen and (min-width: 1000px) {
  .magic-area {
    font-size: 2.33906vw;
  }
}
</style>
